import React from "react";

function AlbumWallShowcase() {
  return (
    <section className="blue-green-gradient text-black py-5">
      <div className="container text-center">
        <div className="album-scrolling-background d-flex align-items-center justify-content-center">
          <div className="bg-white rounded p-4">
            <h1 className="text-success font-weight-normal py-3">My Album Wall</h1>
            <a className="btn btn-success" href="/#/home">
              <i className="bi bi-vinyl"></i> Create Yours
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AlbumWallShowcase;
