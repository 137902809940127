import React, { useEffect } from 'react';
import * as generate from '../scripts/generate'; // import the generate module

function FestivalLineup({ topArtists }) {
  useEffect(() => {
    generate.festivalLineup(topArtists); // call generateFestivalLineup on mount
  }, [topArtists]);

  const handleShare = () => {
    const canvas = document.getElementById("festival-poster");
    canvas.toBlob(function(blob) {
      const file = new File([blob], "wallify-festival.png", {
        type: "image/png",
      });

      if (navigator.share) {
        navigator
          .share({
            title: "Check out my Wallify Festival!",
            text: "I created my fantasy festival with Wallify.me",
            files: [file],
          })
          .then(() => console.log("Shared successfully"))
          .catch((error) => console.log("Error sharing:", error));
      } else {
        console.log("Sharing is not supported on this browser/device\n Downloading instead...");
        handleDownload();
      }
    }, "image/png");
  };

  const handleDownload = () => {
    const canvas = document.getElementById("festival-poster");
    const url = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = url;
    link.download = "wallify-festival.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
  };

  return (
    <section className="orange-red-gradient text-light py-3">
      <div className="container text-center" id='festival-lineup-container'>
        <canvas id="festival-poster"></canvas>
      </div>
      <div className="container text-center py-3">
        <a className="btn btn-light rounded-pill text-success" id="download-festival-poster" onClick={handleDownload}>
          <i className="bi bi-download"></i> Download
        </a>
        <a className="btn btn-light rounded-pill text-success mx-2" id="share-festival-poster" onClick={handleShare}>
          <i className="bi bi-share-fill"></i> Share
        </a>
      </div>
    </section>
  );
}

export default FestivalLineup;