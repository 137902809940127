import React, { useEffect, useState } from 'react';
import Profile from "../components/Profile";
import TopArtistsPyramid from "../components/TopArtistsPyramid";
import AlbumWall from "../components/AlbumWall";
import FestivalLineup from '../components/FestivalLineup';
import Score from '../components/Score'
import Logout from '../components/Logout';
import Loading from '../components/Loading';
import * as auth from "../scripts/auth";
import * as fetch from "../scripts/fetch";

function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [heatIndex, setHeatIndex] = useState(null);
  const [topTracks, setTopTracks] = useState([]);
  const [topArtistImages, setTopArtistImages] = useState([]);
  const [topArtists, setTopArtists] = useState([]);
  const [topAlbumImages, setTopAlbumsImages] = useState([]);

  const [accessToken, setAccessToken] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState("medium_term");

  // Callback function to handle button click in Profile component
  const handleTermSelect = (index) => {
    console.log("Callback returned (Home):", index);
    switch (index) {
      case 0:
        setSelectedTerm("short_term");
        break;
      case 1:
        setSelectedTerm("medium_term");
        break;
      case 2:
        setSelectedTerm("long_term");
        break;
      default:
        setSelectedTerm("medium_term");
    }
  };

  useEffect(() => {
    const navigationEntries = window.performance.getEntriesByType('navigation');
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
      console.log("Page was reloaded");
      window.location.href = '/';
    }
  }, []);

  useEffect(() => {
    const clientId = "819dd040fa2c475eb6c6f26dad643859";
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    console.log("Code -> ", code);
  
    async function fetchData(accessToken) {
      let profileData = await fetch.profile(accessToken);
      setProfile(profileData);

      let heatIndex = await fetch.heatIndex(accessToken, 50, selectedTerm);
      setHeatIndex(heatIndex);
  
      let topTracksData = await fetch.topTracks(accessToken, 10, selectedTerm);
      setTopTracks(topTracksData);
  
      let topArtistImagesData = await fetch.topArtistsImages(accessToken, 9, selectedTerm);
      setTopArtistImages(topArtistImagesData);
  
      let topArtistsData = await fetch.topArtists(accessToken, 20, selectedTerm);
      setTopArtists(topArtistsData);

      let topAlbumImages = await fetch.fetchTopAlbumCovers(accessToken, 20, selectedTerm);
      setTopAlbumsImages(topAlbumImages);
    }
  
    async function fetchInitialData() {
      if (!code) {
        auth.redirectToAuthCodeFlow(clientId);
      } else {
        const accessToken = await auth.getAccessToken(clientId, code);
        console.log("access_token -> ", accessToken);
        setAccessToken(accessToken);
        await fetchData(accessToken);
      }
    }
  
    fetchInitialData().then(() => setIsLoading(false));
  }, []);
  
  useEffect(() => {
    if (selectedTerm && accessToken) {
      async function fetchTermData() {
        const heatIndex = await fetch.heatIndex(accessToken, 50, selectedTerm);
        setHeatIndex(heatIndex);
  
        const topTracksData = await fetch.topTracks(accessToken, 10, selectedTerm);
        setTopTracks(topTracksData);
  
        const topArtistImagesData = await fetch.topArtistsImages(accessToken, 15, selectedTerm);
        setTopArtistImages(topArtistImagesData);
  
        const topArtistsData = await fetch.topArtists(accessToken, 20, selectedTerm);
        setTopArtists(topArtistsData);

        const topAlbumImagesData = await fetch.fetchTopAlbumCovers(accessToken, 10, selectedTerm);
        setTopAlbumsImages(topAlbumImagesData);
      }
  
      fetchTermData();
    }
  }, [selectedTerm, accessToken]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="Home">
      {profile && <Profile profile={profile} onTermSelect={handleTermSelect} />}
      {topTracks && <TopArtistsPyramid topTracks={topTracks}></TopArtistsPyramid>}
      {heatIndex && <Score heatIndex={heatIndex}></Score>}
      {profile && <AlbumWall topArtistImages={topAlbumImages} displayName={profile.display_name}></AlbumWall>}
      {topArtists && <FestivalLineup topArtists={topArtists}></FestivalLineup>}
      <Logout></Logout>
    </div>
  );
  
}

export default Home;