import React, { useState, useEffect } from 'react';
import * as generate from "../scripts/generate";

function AlbumWall(props) {
  const { topArtistImages, displayName } = props;

  useEffect(() => {
    if (topArtistImages.length !== 0) {
      generate.albumWall(topArtistImages, displayName);
    }
  }, [topArtistImages, displayName]);
  
  if (topArtistImages.length === 0) {
    return (
      <section className="yellow-orange-gradient py-5 text-light d-flex align-items-center">
      </section>
    )
  }  
  
  const handleShare = () => {
    const canvas = document.getElementById("album-wall");
    canvas.toBlob(function(blob) {
      const file = new File([blob], "wallify-wall.png", {
        type: "image/png",
      });

      if (navigator.share) {
        navigator
          .share({
            title: "Check out my album wall!",
            text: "I created my custom album wall with Wallify.me",
            files: [file],
          })
          .then(() => console.log("Shared successfully"))
          .catch((error) => console.log("Error sharing:", error));
      } else {
        console.log("Sharing is not supported on this browser/device\n Downloading instead...");
        handleDownload();
      }
    }, "image/png");
  };

  const handleDownload = () => {
    const canvas = document.getElementById("album-wall");
    const url = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = url;
    link.download = "wallify-wall.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className="yellow-orange-gradient py-5">
      <div className="container text-center">
        <canvas id="album-wall"></canvas>
      </div>
      {/* <div className="container text-center py-3">
        <a className="btn btn-light rounded-pill text-success" id="download-album-wall" onClick={handleDownload}>
          <i className="bi bi-download"></i> Download
        </a>
        <a className="btn btn-light rounded-pill text-success mx-2" id="share-album-wall" onClick={handleShare}>
          <i className="bi bi-share-fill"></i> Share
        </a>
      </div> */}
    </section>
  );
}

export default AlbumWall;