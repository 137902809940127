import Loading from '../components/Loading';

export function albumWall(topArtistImages, displayName) {
  const canvas = document.getElementById("album-wall");
  const ctx = canvas.getContext("2d");
  const imgWidth = 320;
  const imgHeight = 320;
  const imagesPerRow = 3;
  const padding = 100;
  const spacing = 20;
  const canvasWidth = imgWidth * imagesPerRow + padding * 2;
  const canvasHeight = imgHeight * Math.ceil(topArtistImages.length / imagesPerRow) + padding * 2;
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  // console.log("Wall Canvas Dimensions = W" + canvas.width + " X H" + canvas.height);
  const backgroundImage = new Image();
  backgroundImage.src = require('../assets/wall-bg.png');
  backgroundImage.onload = () => {
    ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
    let loadedImages = 0;
    topArtistImages.forEach((url, index) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => {
        const x =
          spacing / 2 +
          (index % imagesPerRow) * (imgWidth + spacing) +
          (canvas.width - (imgWidth + spacing) * imagesPerRow) / 2;
        const y =
        spacing / 2 +
          Math.floor(index / imagesPerRow) * (imgHeight + spacing) +
          (canvas.height -
            (imgHeight + spacing) * Math.ceil(topArtistImages.length / imagesPerRow)) /
            2;
        ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
        ctx.shadowBlur = 5;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = -2;
        ctx.drawImage(img, x, y, imgWidth, imgHeight);
        loadedImages++;
        if (loadedImages === topArtistImages.length) {
          ctx.shadowColor = "transparent";
          ctx.shadowBlur = 0;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;
          ctx.font = "bold 32px Futura";
          ctx.fillStyle = "#333333";
          ctx.textAlign = "center";
          let name = displayName;
          let heading = name + "'s Album Wall";
          ctx.fillText(heading.toUpperCase(), canvas.width / 2, 50);
        } else {
          <Loading></Loading>
        }
      };
      img.src = url;
    });
  };
}

function wrapText(ctx, name, x, y, maxWidth, lineHeight) {
  let words = name.split(' ');
  let line = '';
  for (let i = 0; i < words.length; i++) {
    let testLine = line + words[i] + ' ';
    let metrics = ctx.measureText(testLine);
    let testWidth = metrics.width;
    if (testWidth > maxWidth && i > 0) {
      ctx.fillText(line, x, y);
      line = words[i] + ' ';
      y += lineHeight;
    }
    else {
      line = testLine;
    }
  }
  ctx.fillText(line, x, y);
}

export function festivalLineup(topArtists) {
  const headliners = topArtists.slice(0,3)
  const openers = topArtists.slice(3);
  const canvas = document.getElementById("festival-poster");
  const backgroundImage = new Image();
  const ctx = canvas.getContext("2d");
  const padding = 20;
  canvas.width = 1000;
  canvas.height = 1000;
  // console.log("Festival Canvas Dimensions = W" + canvas.width + " X H" + canvas.height);
  ctx.fillStyle = '#54D9F8';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  backgroundImage.src = require("../assets/festival-bg.png");
  backgroundImage.onload = () => {
    ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
    ctx.fillStyle = '#F5FFF9';
    ctx.font = 'bold 40px Futura';
    ctx.textAlign = 'center';
    if (headliners.length !== 0){

      if (headliners[0].length > 10) {
          wrapText(ctx, headliners[0], canvas.width/2, 360, 300, 45);
      } else {
          ctx.fillText(headliners[0], canvas.width/2, 360);
      }

      if (headliners[1].length > 10) {
          wrapText(ctx, headliners[1], canvas.width/2 - 290, 360, 300, 45);
      } else {
          ctx.fillText(headliners[1], canvas.width/2 - 290, 360);
      }

      if (headliners[2].length > 10) {
          wrapText(ctx, headliners[2], canvas.width - 220, 360, 300, 45);
      } else {
          ctx.fillText(headliners[2], canvas.width - 220, 360);
      }
    } else {
      ctx.fillText("Your", canvas.width/2 - 290, 360);
      ctx.fillText("Favs", canvas.width/2, 360);
      ctx.fillText("Here", canvas.width - 220, 360);
    }
    if(openers.length !== 0){
      openers.sort()
      ctx.font = 'bold 32px Verdana';
      const maxWidth = canvas.width - 50;
      const maxNamesPerLine = 4;
      let currentLine = '';
      let namesOnLine = 0;
      const xpos = 500;
      let ypos = 550;
      for (let i = 0; i < openers.length; i++) {
        const name = openers[i];
        const textWidth = ctx.measureText(`${currentLine} ${name}`).width;
        if (textWidth <= maxWidth && namesOnLine < maxNamesPerLine) {
          currentLine += (currentLine ? ' • ' : '') + name;
          namesOnLine++;
        }
        else {
          ypos +=60;
          ctx.fillText(currentLine, xpos, ypos);
          currentLine = name;
          namesOnLine = 1;
        }
      }
      if (currentLine) {
        ypos +=60;
        ctx.fillText(currentLine, xpos, ypos);
      }
    } else {
      ctx.font = 'bold 30px Verdana';
      ctx.fillText("We couldn't find any of your favorite artists :(", 500, 700);
    }
  };
}