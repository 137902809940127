import ProfileSection from '../components/ProfileSection';
import AlbumWallShowcase from '../components/AlbumWallShowcase';
import FestivalLineupShowcase from '../components/FestivalLineupShowcase';
import { useEffect } from 'react';

function LandingPage() {

  useEffect(() => {
    localStorage.clear();
  }, []);
  
  return (
    <div className="LandingPage">
      <ProfileSection></ProfileSection>
      <AlbumWallShowcase></AlbumWallShowcase>
      <FestivalLineupShowcase></FestivalLineupShowcase>
    </div>
  );
}

export default LandingPage;