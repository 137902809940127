import React from 'react';
import { Container } from 'react-bootstrap';

function Loading() {
  return (
    <Container className='green-blue-gradient py-5 d-flex justify-content-center align-items-center vh-100' fluid>
        <div className="lds-ripple"><div></div><div></div></div>
    </Container>
  );
}

export default Loading;