import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BsFire, BsFillQuestionCircleFill } from 'react-icons/bs';

function Score({heatIndex}) {
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    let fireAmt = Math.round(heatIndex/20);
    return (
        <section className="green-yellow-gradient text-light text-center py-3">
            <div className='p-3'>
                <h3>Heat Index <i className="ml-2" onClick={handleShow}><BsFillQuestionCircleFill/></i></h3>
                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header className='bg-danger text-light'>
                        <Modal.Title>What is my Heat Index? 🤔</Modal.Title>
                        <Button variant="danger" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <p>The Heat Index is calculated by the average popularity of your top 50 songs, i.e how 🔥 your music taste is!</p>
                        <p>Don't worry, a lower Heat Index indicates you listen to niche songs that not many people know 😌</p>
                    </Modal.Body>
                </Modal>
            </div>
            {fireAmt >= 5 ? (
                <>
                {[...Array(Math.round(fireAmt))].map((_, index) => (
                    <BsFire key={index} className='text-danger' size={32}/>
                ))}
                </>
            ) : (
                <>
                {[...Array(Math.round(fireAmt))].map((_, index) => (
                    <BsFire key={index} className='text-danger' size={32}/>
                ))}
                {[...Array(Math.round(5 - fireAmt))].map((_, index) => (
                    <BsFire key={index} className='text-light' size={32}/>
                ))}
                </>
            )}
        </section>
    );
}  

export default Score;