import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Spotify_Logo_RGB_White from '../assets/Spotify_Logo_RGB_White.png'

function Footer() {
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const handleClose = () => setShowPrivacyModal(false);
    const handleShow = () => setShowPrivacyModal(true);
    
    return (
        <section>
            <div>
                <Modal show={showPrivacyModal} onHide={handleClose}>
                    <Modal.Header className='bg-success text-light'>
                        <Modal.Title>Privacy Policy 🤫</Modal.Title>
                        <Button variant="success" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                    <p>At Wallify, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and disclose data when you use our website. By accessing and using Wallify, you consent to the terms described in this policy.</p>
                    
                    <h5>Collection of Personal Information</h5>
                    <ul>
                        <li>We may collect certain personal information, such as your name, email address, and Spotify username, to provide personalized services.</li>
                        <li>We may also collect non-personal information, such as your IP address and device information, for analytics and security purposes.</li>
                    </ul>
                    
                    <h6>Use of Personal Information</h6>
                    <ul>
                        <li>We use the collected personal information to enhance your experience, customize content, and provide relevant recommendations.</li>
                        <li>We may use your email address to send updates, newsletters, and promotional materials related to Wallify. You can opt-out of these communications at any time.</li>
                    </ul>
                    
                    <h6>Spotify API Integration</h6>
                    <ul>
                        <li>Wallify uses the Spotify API to retrieve your top songs and artists. We do not store your Spotify credentials.</li>
                        <li>We only access the necessary data from Spotify to display personalized content on Wallify.</li>
                        <li>If at any point you wish to revoke Wallify's permission to generate your custom elements, you can do so <a href="https://support.spotify.com/us/article/spotify-on-other-apps/">here</a>.</li>
                    </ul>
                    
                    <h6>Affiliate Links and Advertisements</h6>
                    <ul>
                        <li>Wallify may display affiliate links and use Google AdSense and Analytics for ads and website usage tracking.</li>
                        <li>Clicking on affiliate links may result in earning a commission for Wallify.</li>
                    </ul>
                    
                    <h6>Data Security</h6>
                    <ul>
                        <li>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</li>
                        <li>However, please note that no method of transmission over the internet or electronic storage is 100% secure.</li>
                    </ul>
                    
                    <h6>Third-Party Links</h6>
                    <p>Wallify may contain links to third-party websites. We are not responsible for their privacy practices or content. Please review their privacy policies.</p>
                    
                    <h6>Children's Privacy</h6>
                    <p>Wallify is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children.</p>
                    
                    <h6>Changes to the Privacy Policy</h6>
                    <p>We may update this Privacy Policy from time to time. Any significant changes will be posted on our website.</p>

                    <p>If you have any questions or concerns regarding our Privacy Policy, please contact us <a href="mailto:wallify@icloud.com">here</a>.</p>
                    <Button variant="success" onClick={handleClose}>
                        Got It
                    </Button>
                    </Modal.Body>
                </Modal>
            </div>
            <footer className="bg-success text-white py-3">
            <br/><br/>
            <div className="container">
                <div className="row">
                <div className="col-md-6">
                    <p>&copy; 2024 Wallify</p>
                </div>
                <div className="col-md-6 text-md-right">
                    <a href="#privacy"  onClick={handleShow} className="text-white mx-2">Privacy Policy</a>
                    <a href="mailto:wallify@icloud.com" className="text-white mx-2">Contact</a>
                </div>
                </div>
            </div>
            <div className='p-3 m-2 text-center'>
                <a className='text-light text-decoration-none' href="https://www.spotify.com">
                    Made for &nbsp;
                    <Image
                        src={Spotify_Logo_RGB_White}
                        alt="Spotify Logo"
                        fluid
                        style={{ width: '70px', maxHeight: '100%', display: 'inline-block' }}
                    />
                </a>
            </div>
            </footer>    
        </section>
        );
}

export default Footer;
