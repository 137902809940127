import React from "react";

function FestivalLineupShowcase() {
  return (
    <section className="festival-showcase green-yellow-green-gradient text-white py-3">
      <div className="container text-center">
        <h1 className="text-white py-3">My Festival Lineup</h1>
        <div className="poster-lineup-background"></div>
        <h5 className="text-light py-3">
          Create a custom festival poster based on your favorite artists
        </h5>
        <a className="btn btn-light text-success" href="/#/home">
          <i className="bi bi-music-note text-success"></i> Create Yours
        </a>
      </div>
    </section>
  );
}

export default FestivalLineupShowcase;
