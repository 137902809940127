import React from 'react';

function ProfileSection() {
  return (
    <section className="green-blue-gradient text-light py-5">
      <div className="container text-center">
        <h1 className="display-4">Hey there,</h1>
        <h2 className="lead py-3">let's see what we've got.</h2>
        <a className="btn btn-light rounded-pill text-success" href="/#/home">
          <i className="bi bi-spotify"></i> Log in with Spotify
        </a>
      </div>
    </section>
  );
}

export default ProfileSection;
