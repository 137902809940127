import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

function Profile(props) {
  const [profile, setProfile] = useState(null);
  const [selectTerm, setSelectTerm] = useState(1);
  
  // console.log("selectTerm (Profile):", selectTerm);

  const termClicked = (index) => {
    setSelectTerm(index);
    props.onTermSelect(index);
  }

  const paintButton = (index) => {
    if (index === selectTerm) {
      return 'success';
    }
    return 'light';
  } 

  useEffect(() => {
    setProfile(props.profile);
  }, [props.profile]);

  return (
    <section className="green-blue-gradient text-light py-3">
      {profile && profile.images && (
        <div className="container text-center">
          <span id="avatar">
            {profile.images[0] && <img src={profile.images[0].url} width={200} height={200} alt="Profile avatar" />}
          </span>
          <h1 className="display-5">Hey <span id="displayName">{profile.display_name}</span>,</h1>
          <h2 className="lead py-3">you've got some interesting picks...</h2>
          <ButtonGroup>
            <Button variant={paintButton(0)} onClick={() => termClicked(0)}>4 Weeks</Button>
            <Button variant={paintButton(1)} onClick={() => termClicked(1)}>6 Months</Button>
            <Button variant={paintButton(2)} onClick={() => termClicked(2)}>All Time</Button>
          </ButtonGroup>
        </div>
      )}
    </section>
  );
}

export default Profile;