import React from 'react';
import { Container, Col } from 'react-bootstrap';
import logo from '../assets/logo-w-t.png'

function Logout() {

    const handleShare = () => {
        const shareUrl = 'https://wallify.me/';
        if (navigator.share) {
          navigator
            .share({
              title: 'Check out Wallify',
              text: 'You can make a custom album wall on Wallify for Spotify',
              url: shareUrl,
            })
            .then(() => console.log('Shared successfully'))
            .catch((error) => console.log('Sharing failed:', error));
        } else {
          console.log("Could not share Wallify, your device or browser is not supported :(");
        }
      };      

    return (
        <Container className='red-blue-gradient p-5 d-flex justify-content-center align-items-center' fluid>
            <div className='row mx-auto'>
                <Col className='col-12 text-center my-3'>
                    <h4 className='text-light'> Enjoying <img src={logo} alt="Wallify" height="50"/>?</h4>
                    <a className="btn btn-light rounded text-success" onClick={handleShare}>
                        <i className="bi bi-share"></i> Share with your friends
                    </a>
                </Col>
                <Col className='col-12 text-center my-3'>
                    <a className="btn btn-light rounded-pill text-danger mx-auto" href="./">
                        <i className="bi bi-box-arrow-left"></i> Log Out
                    </a>
                </Col>
            </div>
        </Container>
    );
}

export default Logout;