export async function profile(token) {
    const result = await fetch("https://api.spotify.com/v1/me", {
        method: "GET", headers: { Authorization: `Bearer ${token}` }
    });

    return await result.json();
}

export async function topTracks(token, limit, time_range) {
  const response = await fetch(`https://api.spotify.com/v1/me/top/tracks?limit=${limit}&time_range=${time_range}&offset=0`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const data = await response.json();
  const topTracks = [];

  data.items.forEach((track) => {
    const cleanTrackName = track.name.replace(/[\[\(].*?[\]\)]/g, '').trim();
    topTracks.push(cleanTrackName);
  });

  return topTracks;
}

export async function heatIndex(token, limit, time_range) {
  const response = await fetch(`https://api.spotify.com/v1/me/top/tracks?limit=${limit}&time_range=${time_range}&offset=0`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const data = await response.json();
  const heatIndexes = [];
  data.items.forEach((track, i) => {
    heatIndexes.push(track.popularity);
    console.log("(" + i + ") Name => " + track.name + " heatIndexe => " + track.popularity);
  });

  console.log("Getting Average of " + heatIndexes.length + " heatIndexes");
  let sum = 0;

  heatIndexes.forEach((score) => {
    sum += score;
    console.log("Score => " + sum);
  });
  
  console.log("Heat Index:" + sum/heatIndexes.length)
  if (isNaN(sum/heatIndexes.length)) {
    return 1;
  } else {
    return sum/heatIndexes.length;
  }
}

export async function topArtists(token, limit, time_range) {
    const response = await fetch(`https://api.spotify.com/v1/me/top/artists?limit=${limit}&time_range=${time_range}&offset=0`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  
    const data = await response.json();
    const topArtists = [];
  
    data.items.forEach((artist) => {
          topArtists.push(artist.name);
    });
  
    return topArtists;
}

export async function topArtistsImages(token, limit, time_range) {
  const response = await fetch(`https://api.spotify.com/v1/me/top/artists?limit=${limit}&time_range=${time_range}&offset=0`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const data = await response.json();
  const topArtists = [];

  data.items.forEach((artist) => {
    const image = artist.images.find(image => image.width === 320 && image.height === 320)?.url;
    if (image) {
      topArtists.push(image);
    }
  });

  const filteredArray = topArtists.filter(image => image !== undefined && image !== null && image !== '');

  const finalArray = [];
  let count = 0;
  for (let i = 0; i < filteredArray.length; i++) {
    if (count === 9) {
      break;
    }
    if (isValidImage(filteredArray[i])) {
      finalArray.push(filteredArray[i]);
      count++;
    }
  }

  console.log("Top Artists Images");
  console.log(finalArray);

  return finalArray;
}

export async function fetchTopAlbumCovers(token, limit, time_range){
  const response = await fetch(`https://api.spotify.com/v1/me/top/tracks?limit=${limit}&time_range=${time_range}&offset=0`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const data = await response.json();
  const topAlbums = [];

  data.items.forEach((item) => {
    const image = item.album.images[1].url;
    if (image) {
      topAlbums.push(image);
    }
  });

  const filteredArray = topAlbums.filter(image => image !== undefined && image !== null && image !== '');

  const finalArray = [];
  let count = 0;
  for(let i = 0; i < filteredArray.length; i++) {
    if (count === 9){
      break;
    }
    if (isValidImage(filteredArray[i])) {
      finalArray.push(filteredArray[i]);
      count++;
    }
  }

  console.log("Top Album Images");
  console.log(topAlbums);

  return finalArray;
}

function isValidImage(image) {
  return image !== undefined && image !== null && image !== '';
}

export async function topGenres(token, limit, time_range) {
    const response = await fetch(`https://api.spotify.com/v1/me/top/artists?limit=${limit}&time_range=${time_range}&offset=0`, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
        }
    });

    const data = await response.json();
    const topGenres = [];

    data.items.forEach((artist) => {
        artist.genres.forEach((genre) => {
        if (!topGenres.includes(genre)) {
            topGenres.push(genre);
        }
        });
    });

    return topGenres;
}

// export async function fetchTopAlbumCovers(token, limit, time_range){
//     const response = await fetch(`https://api.spotify.com/v1/me/top/tracks?limit=${limit}&time_range=${time_range}&offset=0`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`
//       }
//     });

//     const data = await response.json();
//     const topAlbums = [];

//     data.items.forEach(
//         (item) => {
//             topAlbums.push(item.album.images[1].url);
//         }
//     );

//     console.log(topAlbums);

//     return topAlbums;

// }