import React, { useState, useEffect } from 'react';

function TopArtistsPyramid(props) {
  const [topTracks, setTopTracks] = useState([]);

  useEffect(() => {
    setTopTracks(props.topTracks);
  }, [props.topTracks]);

  if (topTracks.length === 0) {
    return (
      <section className="blue-green-gradient text-light d-flex align-items-center" style={{minHeight: '100px'}}>
        <div className='container'>
            <h4 className='text-center'>Not enough listening data ☹️</h4>
        </div>
      </section>
    )
  }

  return (
    <section className="blue-green-gradient text-light">
      <div className="container">
        <table className="top-artists-pyramid">
          <tbody>
            <tr>
              <td className="top-row">{topTracks[0]}</td>
            </tr>
            <tr>
              <td className="second-row">{topTracks[1]}</td>
            </tr>
            <tr>
              <td className="third-row">{topTracks[2]}</td>
            </tr>
            <tr>
              <td className="fourth-row">{topTracks[3]}</td>
            </tr>
            <tr>
              <td className="fifth-row">{topTracks[4]}</td>
            </tr>
            <tr>
              <td className="sixth-row">{topTracks[5]}</td>
            </tr>
            <tr>
              <td className="seventh-row">{topTracks[6]}</td>
            </tr>
            <tr>
              <td className="eight-row">{topTracks[7]}</td>
            </tr>
            <tr>
              <td className="ninth-row">{topTracks[8]}</td>
            </tr>
            <tr>
              <td className="bottom-row">{topTracks[9]}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default TopArtistsPyramid;