import React from 'react';
import NavBar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import logo from '../assets/logo-w-t.png';

function Navbar() {
  return (
    <NavBar bg="success">
      <Container className="justify-content-center">
        <NavBar.Brand href="/">
          <img
            src={logo}
            width="auto"
            height="80"
            className="d-inline-block align-top"
            alt="Wallify Logo"
          />
        </NavBar.Brand>
      </Container>
    </NavBar>
  );
}

export default Navbar;